<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <label>Filter: </label>
          <a-select class="mr-2 ml-2" v-model="selectedkecamatan" placeholder="Kecamatan" style="width: 200px;" @change="kecamatanchange">
            <a-select-option v-for="(data, index) in datakecamatan" :key="index" :value="data.kecamatan">{{ data.kecamatan }}</a-select-option>
          </a-select>
          <a-select class="mr-2" v-model="selectedkelurahan" placeholder="Kelurahan" style="width: 200px;" @change="getDataNasabah">
            <a-select-option v-for="(data, index) in datakelurahan" :key="index" :value="data">{{ data }}</a-select-option>
          </a-select>
          <a-button class="btn btn-outline-danger mr-2" v-if="selectedkecamatan !== null || selectedkelurahan !== null || selectedkualitas !== null" @click="resetFilter">Reset</a-button>
          <!-- <a-button class="btn btn-outline-primary mr-3" @click="locateGeoLocation">My location as center and +100 marker</a-button> -->
          <label>Kualitas:</label>
          <a-select class="ml-2 mr-2" v-model="selectedkualitas" placeholder="Kualitas" style="width: 70px;" @change="filterkualitas">
            <a-select-option v-for="(data, index) in datakualitas" :key="index" :value="data">{{ data }}</a-select-option>
          </a-select>
          <strong class="float-right">Location Monitoring: {{ mapdata.length }} Data</strong>
        </template>
        <div class="row">
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9" style="padding-right: 0 !important">
            <l-map
              style="height: 70vh;"
              ref="myMap"
              :zoom="zoom"
              :center="center"
              @ready="doSomethingOnReady"
            >
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker v-for="(data, index) in mapdata" :key="index" :lat-lng="[data.location.lat, data.location.lng]" :icon="data.status === 1 ? iconPack.number1pin : data.status === 2 ? iconPack.number2pin : data.status === 3 ? iconPack.number3pin : data.status === 4 ? iconPack.number4pin : data.status === 5 ? iconPack.number5pin : iconPack.number1pin">
                <!-- <input
                  id="iconSize"
                  v-model="iconSize"
                  type="range"
                  min="1"
                  max="200"
                  value="64"
                /> -->
                <!-- <l-icon
                  v-if="data.status === 1"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  icon-url="./component/marker1.png"
                />
                <l-icon
                  v-if="data.status === 2"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  icon-url="./component/marker2.png"
                />
                <l-icon
                  v-if="data.status === 3"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  icon-url="./component/marker3.png"
                />
                <l-icon
                  v-if="data.status === 4"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  icon-url="./component/marker4.png"
                />
                <l-icon
                  v-if="data.status === 5"
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  icon-url="./component/marker5.png"
                /> -->
              <!-- <l-circle-marker v-for="(data, index) in mapdata" :key="index"
              :radius="data.radius"
              :color="data.status === 1 ? '#1EAE98' : data.status === 2 ? '#05DFD7' : data.status === 3 ? '#FFA36C' : data.status === 4 ? '#FF5722' : data.status === 5 ? '#DD2C00' : '#3388ff'"
              :fillColor="data.status === 1 ? '#1EAE98' : data.status === 2 ? '#05DFD7' : data.status === 3 ? '#FFA36C' : data.status === 4 ? '#FF5722' : data.status === 5 ? '#DD2C00' : '#3388ff'"
              :lat-lng="[data.location.lat, data.location.lng]"> -->
                <l-popup v-if="data.isme">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>You are here!</strong>
                    </div>
                  </div>
                </l-popup>
                <l-popup v-else>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Nama: </strong> {{ data.name }}
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Saldo Pokok: </strong> {{ formatCurrency(data.pokok) }}
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Tunggakan: </strong>{{ formatCurrency(data.tunggakanpokok) + '(Pokok)' }}, {{ formatCurrency(data.tunggakanbunga) + '(Bunga)' }}, {{ formatCurrency(data.tunggakanpokok + data.tunggakanbunga) + '(Total)' }}
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Alamat: </strong> {{ data.address }}
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>No HP: </strong> {{ data.no_hp }} <a :href="'https://wa.me/' + generatePhoneNumber(data.no_hp)" target="blank">Open in Whatsapp</a>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <a :href="data.url" target="blank">Open google map for direction</a>
                    </div>
                  </div>
                </l-popup>
              </l-marker>
              <!-- <l-marker :lat-lng="[-8.003139, 112.612112]" :icon="iconPack.number5pin">
                <l-popup>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Nama:</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Alamat:</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Tagihan: </strong>
                    </div>
                  </div>
                </l-popup>
              </l-marker> -->
            </l-map>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3" style="padding-left: 0 !important">
            <vue-custom-scrollbar style="height: 70vh; padding: 20px;">
              <div v-for="(data, index) in datacard" :key="index">
                <div class="card mb-3 border-primary">
                  <div class="card-body" style="padding: 1.33rem 1rem;">
                    <h5 class="card-title">{{ data.kecamatan }}</h5>
                    <p class="card-text text-primary float-right">
                      <label class="float-right" style="margin: 0 !important">NPL {{ data.npl }}%</label>
                      <br>
                      <label class="float-right" style="margin: 0 !important">{{ data.jumlahnasabah }} Nasabah</label>
                      <br>
                      <label class="float-right" style="margin: 0 !important">Rp. {{ formatCurrency(data.totalkredit) }}</label>
                    </p>
                  </div>
                </div>
                <a-divider type="horizontal" />
              </div>
            </vue-custom-scrollbar>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import marker1 from './component/marker1.png'
import marker2 from './component/marker2.png'
import marker3 from './component/marker3.png'
import marker4 from './component/marker4.png'
import marker5 from './component/marker5.png'

const iconPack = {
  number1pin: L.icon({
    iconUrl: marker1,
    iconSize: [52, 57],
    iconAnchor: [26, 57],
  }),
  number2pin: L.icon({
    iconUrl: marker2,
    iconSize: [52, 57],
    iconAnchor: [26, 57],
  }),
  number3pin: L.icon({
    iconUrl: marker3,
    iconSize: [52, 57],
    iconAnchor: [26, 57],
  }),
  number4pin: L.icon({
    iconUrl: marker4,
    iconSize: [52, 57],
    iconAnchor: [26, 57],
  }),
  number5pin: L.icon({
    iconUrl: marker5,
    iconSize: [52, 57],
    iconAnchor: [26, 57],
  }),
}
// const cssiconPack = {
//   number1pin: L.divIcon({
//     className: 'my-custom-pin',
//     iconAnchor: [0, 24],
//     labelAnchor: [-6, 0],
//     popupAnchor: [0, -36],
//     html: '<div class="number1pin">',
//   }),
//   number2pin: L.divIcon({
//     className: 'my-custom-pin',
//     iconAnchor: [0, 24],
//     labelAnchor: [-6, 0],
//     popupAnchor: [0, -36],
//     html: '<div class="number2pin">',
//   }),
//   number3pin: L.divIcon({
//     className: 'my-custom-pin',
//     iconAnchor: [0, 24],
//     labelAnchor: [-6, 0],
//     popupAnchor: [0, -36],
//     html: '<div class="number3pin">',
//   }),
//   number4pin: L.divIcon({
//     className: 'my-custom-pin',
//     iconAnchor: [0, 24],
//     labelAnchor: [-6, 0],
//     popupAnchor: [0, -36],
//     html: '<div class="number4pin">',
//   }),
//   number5pin: L.divIcon({
//     className: 'my-custom-pin',
//     iconAnchor: [0, 24],
//     labelAnchor: [-6, 0],
//     popupAnchor: [0, -36],
//     html: '<div class="number5pin">',
//   }),
// }

export default {
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD()
    // })
    this.getDataKecamatan()
  },
  components: {
    vueCustomScrollbar,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      datacard: [],
      selectedkualitas: null,
      selectedkecamatan: null,
      selectedkelurahan: null,
      datakualitas: [],
      datakecamatan: [],
      datakelurahan: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">Map Beta</a> contributors',
      zoom: 13,
      center: [-7.977102504856904, 112.63406369264047],
      markerLatLng: [51.504, -0.159],
      staticAnchor: [-8.003289, 112.610641],
      iconSize: 64,
      customIcon: L.icon({
        iconUrl:
          'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        iconSize: this.dynamicSize,
        iconAnchor: [-8.003289, 112.610641],
      }),
      iconPack,
      data: [],
      backupData: [],
      mapdata: [],
    }
  },
  methods: {
    generatePhoneNumber(value) {
      var ret = value.replaceAll(' ', '').replaceAll('-', '')
      return ret
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15]
    },
    filterkualitas() {
      var newData = this.backupData.filter(x => x.kualitas === this.selectedkualitas)
      this.data = this.$g.clone(newData)
      this.generateTheLocation()
    },
    resetFilter() {
      this.selectedkualitas = null
      this.selectedkecamatan = null
      this.selectedkelurahan = null
      this.getDataNasabah()
    },
    kecamatanchange() {
      this.selectedkelurahan = null
      this.datakelurahan = []
      var targetIndex = this.datakecamatan.findIndex(x => x.kecamatan === this.selectedkecamatan)
      if (targetIndex >= 0) {
        this.datakelurahan = this.datakecamatan[targetIndex].kelurahan
        this.getDataNasabah()
      }
    },
    async getDataKecamatan() {
      var resd = await lou.customUrlGet('loc/dashboard', false, true)
      if (resd) {
        this.datacard = resd.data.blokAtas
      }
      var res = await lou.customUrlGet('loc/list/kecamatan', false, true)
      if (res) {
        this.datakecamatan = res.data
      }
      this.getDataNasabah()
    },
    async getDataNasabah() {
      var imbuhan = this.selectedkecamatan !== null ? '?kecamatan=' + this.selectedkecamatan : ''
      imbuhan += this.selectedkelurahan !== null ? '&kelurahan=' + this.selectedkelurahan : ''
      var res = await lou.customUrlGet('loc' + imbuhan, false, true)
      if (res) {
        // var me = {
        //   isme: true,
        //   norekening: '',
        //   name: '',
        //   address: element.alamat,
        //   pokok: element.saldopokok,
        //   tunggakanpokok: element.tunggakanpokok,
        //   tunggakanbunga: element.tunggakanbunga,
        //   location: {
        //     lat: parseFloat(element.longitude),
        //     lng: parseFloat(element.latitude),
        //   },
        //   status: parseInt(element.kualitas),
        //   url: element.url,
        // }
        if (res.data.length !== 0) {
          this.data = res.data
          this.generateTheLocation()
          this.backupData = this.$g.clone(res.data)
          var groupbykualitas = this.backupData.reduce(function (r, a) {
            if (a.kualitas !== undefined && a.kualitas !== null) {
              r[a.kualitas] = r[a.kualitas] || []
              r[a.kualitas].push(a)
            }
            return r
          }, Object.create(null))
          var kualitas = Object.keys(groupbykualitas)
          this.datakualitas = kualitas
        }
      }
      // console.log('res.data', res.data)
    },
    // requestLocation() {
    //   navigator.permissions.query({
    //     name: 'geolocation',
    //   }).then(function(result) {
    //     if (result.state === 'granted') {
    //       this.report(result.state)
    //       geoBtn.style.display = 'none'
    //     } else if (result.state === 'prompt') {
    //       this.report(result.state)
    //       geoBtn.style.display = 'none'

    //       navigator.geolocation.getCurrentPosition(revealPosition, positionDenied, geoSettings)
    //     } else if (result.state === 'denied') {
    //       this.report(result.state)
    //       geoBtn.style.display = 'inline'
    //     }
    //     result.onchange = function() {
    //       this.report(result.state)
    //     }
    //   })
    // },
    generateTheLocation() {
      this.mapdata = []
      this.data.forEach(element => {
        var nradius = Math.floor(Math.random() * 20) + 1
        var toArr = {
          norekening: element.norekening,
          name: element.nama,
          address: element.alamat,
          pokok: element.saldopokok,
          no_hp: element.no_hp,
          tunggakanpokok: element.tunggakanpokok,
          tunggakanbunga: element.tunggakanbunga,
          location: {
            lat: parseFloat(element.longitude),
            lng: parseFloat(element.latitude),
          },
          status: parseInt(element.kualitas),
          url: element.url,
          radius: nradius,
        }
        // console.log('element.latitude', element.latitude)
        // console.log('element.longitude', element.longitude)
        // console.log('toArr', toArr)
        this.mapdata.push(toArr)
      })
    },
    generateRandomLocation() {
      var randomloc = []
      for (let i = 0; i < 100; i++) {
        var ret = this.randomGeo({ latitude: -8.003289, longitude: 112.610641 }, 100000)
        var toArr = {
          name: 'Sunarto',
          address: 'Jl. Kemantren ' + i + ' no 2 rt 04 rw 13 pagar hitam',
          pokok: i * 10000000,
          tunggakanbunga: i + 100000,
          total: 0,
          location: {
            lat: ret.latitude,
            lng: ret.longitude,
          },
          status: Math.floor(Math.random() * 5) + 1,
        }
        this.mapdata.push(toArr)
      }
      console.log('randomloc', randomloc)
    },
    randomGeo(center, radius) {
      var y0 = center.latitude
      var x0 = center.longitude
      var rd = radius / 111300

      var u = Math.random()
      var v = Math.random()

      var w = rd * Math.sqrt(u)
      var t = 2 * Math.PI * v
      var x = w * Math.cos(t)
      var y = w * Math.sin(t)

      return {
        latitude: y + y0,
        longitude: x + x0,
      }
    },
    report(state) {
      console.log('Permission ' + state)
    },
    doSomethingOnReady() {
      // console.log('this.$refs.myMap', this.$refs.myMap.mapObject)
      this.map = this.$refs.myMap.mapObject
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition(res => {
        this.me = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
        setTimeout(() => {
          this.zoom = 10
          this.generateRandomLocation()
        }, 500)
      })
    },
    formatCurrency(value) {
      return lou.curency(value)
    },
    getDynamicColor(index) {
      var ret = lou.dynamicColors(index)
      return ret
    },
  },
}
</script>

<style>
.number1pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #1EAE98;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.number2pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #05DFD7;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.number3pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #FFA36C;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.number4pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #FF5722;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.number5pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #DD2C00;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.number1pin:after,
.number2pin:after,
.number3pin:after,
.number4pin:after,
.number5pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #ffff;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.bounce-1p5 {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1.5s;
}
.bounce-2 {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 2s;
}
.bounce-2p5 {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 2.5s;
}

.number1pinpulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.number2pinpulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.number3pinpulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.number4pinpulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.number5pinpulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.number1pinpulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #1EAE98;
  animation-delay: 1.1s;
}
.number2pinpulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #05DFD7;
  animation-delay: 1.1s;
}
.number3pinpulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #FFA36C;
  animation-delay: 1.1s;
}
.number4pinpulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #FF5722;
  animation-delay: 1.1s;
}
.number5pinpulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #DD2C00;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
</style>
